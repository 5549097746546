import { createRouter, createWebHistory } from "vue-router";
import { useHead } from "@vueuse/head";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomePage.vue"),
        meta: {
            title: "照片风格转换 - 照片生成器",
            description: "照片风格转换",
        },
    },
    {
        path: "/auth",
        name: "auth",
        component: () => import("@/views/AuthPage.vue"),
        meta: { title: "登录 - 照片生成器", description: "登录" },
    },
    {
        path: "/profile",
        name: "ProfileView",
        component: () => import("@/views/ProfileView.vue"),
        meta: { title: "个人中心 - 照片生成器", description: "个人中心" },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    useHead({
        title: to.meta.title || "照片生成器",
        meta: [
            {
                name: "description",
                content: to.meta.description || "",
            },
        ],
    });
    next();
});

export default router;
