import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router"; // 引入 router
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/styles/global.css";
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import "./assets/main.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

// 添加 vconsole 的条件初始化
const initVConsole = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get("_debug");

    if (debug === "mediafactory") {
        const script = document.createElement("script");
        script.src =
            "https://lf6-cdn-tos.bytecdntp.com/cdn/expire-1-M/vConsole/3.12.1/vconsole.min.js";
        script.onload = () => {
            new window.VConsole();
        };
        document.head.appendChild(script);
    }
};

// 初始化 vconsole
initVConsole();

const app = createApp(App);
const head = createHead();

app.use(head);
app.use(router);
app.use(ElementPlus, {
    locale: zhCn,
});
app.use(CkeditorPlugin);
app.mount("#app");
